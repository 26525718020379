/* ==========================================================================
   Admin Login Colors
   ========================================================================== */
/*
//       _/_/_/    _/_/    _/      _/  _/_/_/_/  _/_/_/    _/_/_/
//    _/        _/    _/  _/_/    _/  _/          _/    _/
//   _/        _/    _/  _/  _/  _/  _/_/_/      _/    _/  _/_/
//  _/        _/    _/  _/    _/_/  _/          _/    _/    _/
//   _/_/_/    _/_/    _/      _/  _/        _/_/_/    _/_/_/
*/
/* ==========================================================================
   Colors & Shades
   ========================================================================== */
/***** DEFAULT COLORS *****/
/***** TEXT SELECTION COLOR *****/
::selection {
  background: #2D1C49;
  color: #fff; }

::-moz-selection {
  background: #2D1C49;
  color: #fff; }

/***** WIDTHS & BREAKPOINTS *****/
/* ==========================================================================
   Fonts
   ========================================================================== */
/* ==========================================================================
   Mixins
   ========================================================================== */
/* ==========================================================================
   Animations
   ========================================================================== */
@keyframes animate-display-on {
  0% {
    display: none;
    opacity: 0; }
  1% {
    display: block;
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes animate-display-off {
  0% {
    display: block;
    opacity: 1; }
  99% {
    display: block;
    opacity: 0; }
  100% {
    display: none; } }

@keyframes submenu-display-on {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(-15px); }
  1% {
    display: block;
    opacity: 0;
    transform: translateY(-15px); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes submenu-display-off {
  0% {
    display: block;
    opacity: 1;
    transform: translateY(0); }
  99% {
    display: block;
    opacity: 0;
    transform: translateY(-15px); }
  100% {
    display: none;
    transform: translateY(-15px); } }

/* ==========================================================================
   Media Queries
   ========================================================================== */
body.login {
  background: #2D1C49; }

.login.wp-core-ui .button-primary {
  background: #BD10E0;
  border-color: #950db0 #810b99 #810b99;
  box-shadow: 0 1px 0 #810b99;
  text-shadow: 0 -1px 1px #810b99, 1px 0 1px #810b99, 0 1px 1px #810b99, -1px 0 1px #810b99; }
  .login.wp-core-ui .button-primary:hover, .login.wp-core-ui .button-primary:focus {
    background: #c911ee;
    border-color: #810b99;
    color: #fff;
    box-shadow: 0 1px 0 #810b99; }
  .login.wp-core-ui .button-primary:focus {
    box-shadow: inset 0 1px 0 #950db0, 0 0 2px 1px #33b3db; }
  .login.wp-core-ui .button-primary:active {
    background: #950db0;
    border-color: #810b99;
    box-shadow: inset 0 2px 0 #810b99; }
  .login.wp-core-ui .button-primary[disabled], .login.wp-core-ui .button-primary:disabled, .login.wp-core-ui .button-primary.button-primary-disabled, .login.wp-core-ui .button-primary.disabled {
    color: #cfc7d1 !important;
    background: #9d0dba !important;
    border-color: #810b99 !important;
    text-shadow: none !important; }

.login p#nav a,
.login p#backtoblog a {
  color: #fff;
  opacity: .5;
  transition: 150ms all ease-in-out; }

.login p#nav a:hover,
.login p#backtoblog a:hover {
  opacity: 1;
  color: #fff; }

.login p#backtoblog a:active {
  color: #BD10E0; }

.login p.message {
  background: #0fa60b;
  color: #fff;
  border-left: 0; }

.login p.message a {
  color: #fff; }

.login div#login_error {
  background: #ea3009;
  color: #fff;
  border-left: 0; }

.login div#login_error a {
  color: #fff; }
